import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import '@evag/ec-react/dist/main.css';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import styles from './contrib.module.scss';
import MenuMain from '../components/MenuMain';

import grafismo from '../images/grafismo-cinza.png';

var debates = [
    {
        titulo: '[FOLHA DE S.PAULO] ‘Borba Gato deve cair’: não se trata de revisar a história, mas sim o lugar de heróis que nela concedemos a genocidas',
        url: 'https://www1.folha.uol.com.br/colunas/thiago-amparo/2020/06/borba-gato-deve-cair.shtml',
        descr: 'Por Thiago Amparo - Advogado, é professor de direito internacional e direitos humanos na FGV Direito SP. Doutor pela Central European University (Budapeste), escreve sobre direitos e discriminação. (14/6/20)'
    },
    {
        titulo: '[FOLHA DE S.PAULO] Monumentos públicos de figuras controversas da história deveriam ser retirados? SIM',
        descr: 'Por Hélio Menezes, antropólogo, crítico, pesquisador e curador de arte contemporânea do Centro Cultural São Paulo. (19/06/20)',
        url: 'https://www1.folha.uol.com.br/opiniao/2020/06/monumentos-publicos-de-figuras-controversas-da-historia-deveriam-ser-retirados-sim.shtml'
    },
    {
        titulo: '[EL PAÍS] A memória histórica da escravidão é motivo de guerra nas escolas dos Estados Unidos',
        url: 'https://brasil.elpais.com/internacional/2021-07-25/a-memoria-historica-da-escravidao-e-motivo-de-guerra-nas-escolas-dos-estados-unidos.html',
        descr: 'Estados republicanos impõem leis para proibir o que consideram um ensino que culpa os brancos. “Tentam fazer retroceder o ensino da parte mais incômoda de nossa história”, (25/7/21)'
    },
    {
        titulo: '[CBN] ‘Decisão de instalar uma estátua como a de Borba Gato é mais violenta do que a de queimar',
        url: 'https://m.cbn.globoradio.globo.com/media/audio/348091/decisao-de-instalar-uma-estatua-como-de-borba-gato.htm',
        descr: 'Escritor Abílio Ferreira fala sobre estátua de Borba Gato incendiada em SP, evidenciando a violência simbólica que esses monumentos representam. "Não se trata de ser a favor ou contra, mas compreender a disputa de narrativa na cidade", diz. (26/7/21)',
    },
    {
        titulo: '[JACOBIN] Derrubar estátuas não apaga a história, faz com que a vejamos com mais clareza',
        url: 'https://jacobin.com.br/2021/07/derrubar-estatuas-nao-apaga-a-historia-faz-com-que-a-vejamos-com-mais-clareza/',
        descr: 'Os manifestantes que derrubam monumentos de escravocratas e genocidas costumam ser acusados de “apagar o passado”. Mas suas ações estão provocando uma análise mais minucioso sobre as figuras que esses monumentos celebram – permitindo que a história seja recontada do ponto de vista de suas vítimas. Texto de Enzo Traverso, historiador italiano. Atualmente leciona na Universidade de Cornell. (Tradução de Mauro Costa Assis) (25/07/21)',
    },
    {
        titulo: '[BUALA] Porque são as estátuas derrubadas?',
        url: 'https://www.buala.org/pt/cidade/por-que-sao-as-estatuas-derrubadas',
        descr: 'Por Jorge Victor (Doutor em História pela UFF e Professor História da América na UFRJ) e Saulo Castilho (Mestre em História Comparada pela UFRJ).'
    }
]

var imprensa = [
    {
        titulo: '[ALMA PRETA] Rua em homenagem a bandeirante destruidor de Palmares pode mudar de nome',
        url: 'https://almapreta.com/sessao/politica/rua-em-homenagem-a-bandeirante-destruidor-de-palmares-pode-mudar-de-nome',
        data: '31/03/21',
    },
    {
        titulo: '[TV CULTURA] Movimento "SP é Solo Preto e Indígena" luta pela memória e justiça racial na cidade',
        url: 'https://cultura.uol.com.br/noticias/18890_sp-e-solo-preto-e-indigena-movimento-luta-pela-memoria-e-justica-racial-na-cidade.html',
        data: '16/04/21',
    },
    {
        titulo: '[UOL] Movimento negro faz atos pelo Brasil no dia que marca o fim da escravidão',
        url: 'https://noticias.uol.com.br/cotidiano/ultimas-noticias/2021/05/13/protestos-13-de-maio-dia-da-abolicao.htm',
        data: '13/05/21',
    },
    {
        titulo: '[YAHOO] Em SP, atos contra homenagens escravocratas e eugenistas marcam dia da abolição',
        url: 'https://br.noticias.yahoo.com/em-sp-atos-contra-estatuas-de-escravocratas-e-eugenistas-marcam-dia-da-abolicao-185101039.html',
        data: '13/05/21'
    },
    {
        titulo: '[G1] Atos em SP marcam 133 anos da abolição da escravatura no Brasil',
        url: 'https://g1.globo.com/sp/sao-paulo/noticia/2021/05/13/atos-em-sp-marcam-133-anos-da-abolicao-da-escravatura-no-brasil.ghtml',
        data: '13/05/21',
    },
    {
        titulo: '[G1] Manifestantes protestam contra racismo, genocí­dio negro e presidente Bolsonaro na Avenida Paulista',
        url: 'https://g1.globo.com/sp/sao-paulo/noticia/2021/05/13/manifestantes-protestam-contra-racismo-genocidio-negro-e-presidente-bolsonaro-na-avenida-paulista.ghtml',
        data: '13/05/21'
    },
    {
        titulo: '[CLAUDIA] “Resgatar memória é afirmar nossa vida no presente”, diz Luana Alves sobre luta antirracista',
        url: 'https://claudia.abril.com.br/noticias/estatuas-escravocratas-sao-paulo-luana-alves/',
        data: '13/05/21',
    },
    {
        titulo: '[ELÁSTICA] Por uma luta antissistêmica',
        url: 'https://elastica.abril.com.br/especiais/luana-alves-vereadora-sus/',
        data: '22/07/21'
    }
]

export default function render({data}) {
    return (
        <Layout>
            <SEO title="Contribuições"/>

            <MenuMain data={data}/>

            <main className={styles.main}>
                <div style={{height: '68px', backgroundImage: `url(${grafismo})`}}/>

                <div className="wrap">
                    <h1 className="title">CONTRIBUIÇÕES</h1>

                    <p><strong>Nesta página estão reunidos diversos conteúdos que aprofundam o debate sobre homenagem, patrimônio, história, colonialidade e antirracismo. Se você ou a instituição que faz parte pode contribuir para essa discussão através de pareceres técnicos, notícias, colunas, entrevistas, material audiovisual ou outros conteúdos, escreva para sp.solopreto@gmail.com que entraremos em contato.</strong></p>

                    <hr/>

                    <h2>PARECERES TÉCNICOS</h2>

                    <p><a href="/parecer_SP_Solo_Preto_e_Indigena_IVH_24jun21.pdf">Parecer Técnico pelo Instituto Vladimir Herzog (24/06/21)</a><br/>
O Instituto Vladimir Herzog é uma instituição sem fins lucrativos criada em 25 de junho de 2009 com o objetivo de preservar a memória do jornalista Vladimir Herzog e promover ações que atraiam a atenção da sociedade aos problemas sociais e econômicos do Brasil com ênfase nas consequências do golpe de 1964.
</p>

                    <hr/>

                    <h2>DEBATE PÚBLICO SOBRE MEMÓRIA E ANTIRRACISMO</h2>

                    <ul>
                        {
                            debates.map(d => <li key={d.url}>
                                <a href={d.url}>{d.titulo}</a><br/>{d.descr}
                            </li>)
                        }
                    </ul>

                    <hr/>

                    <h2>‘SP É SOLO PRETO E INDÍGENA’ NA IMPRENSA</h2>

                    <ul>
                        {
                            imprensa.map(d => <li key={d.url}>
                                <a href={d.url}>{d.titulo}</a> ({d.data})
                            </li>)
                        }
                    </ul>

                </div>
            </main>
        </Layout>
    );
}

render.propTypes = {
    data: PropTypes.object,
    lapis: PropTypes.object
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    grafismo: file(relativePath: { eq: "grafismo-cinza.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    logo: file(relativePath: { eq: "pretoindigena.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  
      instagram: file(relativePath: { eq: "instagram.png" }) {
          childImageSharp {
            fluid(maxWidth: 30) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    
  }
`;
