import React from 'react';
import PropTypes from 'prop-types';

import GenMenu from '../components/Menu';

const Menu = ({data}) => (
    <GenMenu
        logo={data.logo.childImageSharp.fluid}
        items={[
            {to: "/#manifesto", name: "Manifesto: Assine"},
            {to: "/contribuicoes", name: "Contribuições"},
            {to: "/#iniciativas", name: "Iniciativas"},
            {to: "/pressione", name: "Pressione"},
        ]}
        instagram={{
            logo: data.instagram.childImageSharp.fluid,
            user: "solopretoeindigena"
        }}
        site={data.site.meta}
    />
);

Menu.propTypes = {
    logo: PropTypes.object,
    items: PropTypes.array,
    instagram: PropTypes.object,
    site: PropTypes.object
};

export default Menu;
